import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

//component
import Menu from "../components/Menu"
//css
import "../css/nav.scss"

//data
const getData = graphql`
  {
    c: allContentfulFiche(sort: { fields: name, order: ASC }) {
      nodes {
        name
        id
      }
    }
  }
`
const Fiches = () => {
  const data = useStaticQuery(getData)
  let rawData = data.c.nodes.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.name[0]
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] }
    // if there is push current element to children array for that letter
    else r[group].children.push(e)
    // return accumulator
    return r
  }, {})

  let result = Object.values(rawData)

  return (
    <div>
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
      </div>
      <h1>Fiches</h1>
      <div className="fiches">
        {result.map((alpha, index) => {
          return (
            <div key={index}>
              <h3>{alpha.group}</h3>
              {alpha.children.map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`/fiche/${item.id}`}>{item.name}</Link>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Fiches
